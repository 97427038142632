import React from "react"
import { SRLWrapper } from "simple-react-lightbox"
import Banner from "../../components/Banner"
import Layout from "../../components/core/Layout"
import SEO from "../../components/core/SEO"
import Images from "../../components/Images"

const CommunityClayPage: React.FC = () => (
  <Layout activePage="projects">
    <SEO title="Community Clay Project" />
    <Banner>
      <p>
        <b>This project is now completed.</b><br />
        Thank you to everyone that supported and took part in this project.
        The completed piece will be available to view in Springfields Outlet Shopping &amp; Leisure in Spalding.<br />
        <span className="timescale">This project ran from March 2021 to May 2021.</span>
      </p>
    </Banner>
    <h1 className="page-title">Community Clay</h1>
    <div className="community-clay__container">
      <div>
        <p>
          We have funding from an organisation called Transported to offer
          lonely and isolated people living in the South Holland and Boston area
          the opportunity to make a clay tile, which we will fire and publicly
          display either online or at a public venue along with everyone else's
          tiles.
        </p>
        <p>
          There is <b>no cost to participants</b>, and we will adhere to all
          COVID-19 recommendations and protocols.
        </p>
        <h3 className="section-header">Do I need to be artistic?</h3>
        <p>
          You don't need to be artistic, nor do you need to have worked with
          clay before. You can ask anyone to help, and we will support you with
          simple ideas and techniques.
        </p>
        <h3 className="section-header">What's included?</h3>
        <p>
          You'll be provided with a free kit, which contains all that you'll
          need to construct the tile. The design of the tile is completely up to
          you, adding designs and images you want to the tile to reflect your
          experience. We'll be making a tile along with you, and you'll be
          amazed at how easy it could be!
        </p>
        <p>
          The tile kit will contain some simple instructions, clay, a plastic
          sheet to protect your table and a cotton sheet to roll or beat the
          clay onto. The kit will also contain a template showing the size and
          thickness that the tile needs to be before you add your own designs.
          We need all the tiles to be roughly the same size so that they will
          all fit with each other.
        </p>
        <p>
          We'll deliver your tile kit, and will collect it from you when you're
          done. We will then fire your clay tile to make it permanent, and then
          mount your tile along with other people's tiles onto a mural or wall
          hanging. We plan to publicly display the result so that the wider
          community can also relate to the images, bringing us all closer
          together.
        </p>
        <p>
          We also have a Facebook page for participants to share their work,
          ideas &amp; experience. We hope this will offer participants the
          opportunity to make new connections.
        </p>
        <h3 className="section-header">How do I sign up?</h3>
        <p>
          To take part and apply for your clay tile kit, contact us (Mark &amp;
          Jo) at{" "}
          <a href="mailto:enquiries@greenfieldpottery.co.uk">
            enquiries@greenfieldpottery.co.uk
          </a>
          . Feel free to contact us on{" "}
          <a href="tel:01406425866">01406 425 866</a> for any questions you have
          or for any further information.
        </p>
        <h3 className="section-header">Other Projects run by Transported</h3>
        <p>
          Transported is also offering other art activities as part of the
          programme, including writing, letter writing/penpals, drawing and
          design.{" "}
          <a
            href="https://www.transportedart.com/projects/current-projects/creative-response-to-loneliness-programme-overview/"
            target="_blank"
            aria-label="here (opens in a new tab)"
            rel="noopener noreferrer"
          >
            Click here
          </a>{" "}
          to learn more.
        </p>
      </div>
      <SRLWrapper>
        <Images image="communityclaylarge" />
      </SRLWrapper>
    </div>
  </Layout>
)

export default CommunityClayPage
